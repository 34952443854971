import styled from '@tanium/react-emotion-9';
import { ThemeColors } from '@tanium/theme-data';
import { css } from '@tanium/style-system';
import { Box } from '@tanium/react-box';

export const AppHeaderStyle = {
  borderHeight: 4,
  containerHeight: 50,
  marginHeight: 10,
};

const ContainerBox = styled(Box)(
  css({
    color: ThemeColors.Active2,
    bg: ThemeColors.HeaderTier2,
    height: `${AppHeaderStyle.containerHeight}px`,
    width: '100%',
    alignItems: 'center',
    display: 'flex',
    whiteSpace: 'nowrap',
  })
);

export default ContainerBox;
